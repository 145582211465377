<template>
    <div>
        <search-area :title="!apiloading?show_property_found:'loading...'" height="0"></search-area>
        <v-container>
<!--            <mylistingcard v-for="(card,i) in currentproperties"  :card="card"></mylistingcard>-->
            <big-property-card width="" :property="card" :show-save-icon="false" v-for="(card,i) in current_properties" :key="card.id" ></big-property-card>

            <div style="text-align:center;" v-if="showLoadMore">
                <v-btn style="color: #fff !important;" class="mb-20" elevation="0" type="submit" :loading="apiloading" :disabled="apiloading" width="120" color="secondary"
                        @click="loadMore">Load More</v-btn>
            </div>
        </v-container>
        
    </div>
</template>
<script>
import {loadingMixin} from "../../../mixins/Loading";

export default {
    inject:["Bus"],
    mixins:[loadingMixin],
     data() {
        return {
            pageTitle:"My Listing",
            inset:true,
            properties:[],
            properties_found:0,
            nextUrl:0,
            showLoadMore:true,
            propertyIsEmpty:false,
            path:'',
        }
    },
    computed:{
          show_property_found(){
      return this.properties_found + ' Properties Found'
    },
       current_properties(){
            let p =  this.$store.state.property_module.mylisting
            this.properties = p.data;
            this.propertyIsEmpty = this.checkArrayLength(p.data)
            this.nextUrl = p.current_page+ 1;
            this.path = p.path;
           if(p.total > 10){
               this.showLoadMore = true;
           }

           this.properties_found = p.total
            return this.properties;
        },

    },
    methods:{
        getMyListingProperties(){
            this.$store.dispatch("mylistingPropertyAction")
        },
        loadMore(){
            this.$store.dispatch("loadMoreAction",this.nextUrlLink).then((res)=>{

                if(res.last_page === res.current_page && res.total <=10){
                    return this.showLoadMore = false;
                }
                res.data.forEach(data=>{
                    this.properties.push(data);
                })
                this.nextUrlAction({next_page_url:res.next_page_url,current_page:res.current_page});

                return this.showLoadMore = true;
            })
        },
    },
     mounted(){
             this.Bus.$emit('title-change',this.pageTitle)
             this.getMyListingProperties();
         }
}
</script>
<style scoped>
 
</style>